import Launch from "modules/landing/launch";
import StartedLaunch from "modules/landing/startedLaunch";
import ThankYou from "components/ThankYou";

export const HOME_PAGE = "HOME_PAGE";
export const START_PAGE = "START_PAGE";
export const THANK_YOU_PAGE = "THANK_YOU_PAGE";

export const SpradlingShowroomRoutes = {
  [HOME_PAGE]: {
    es: "",
    en: "",
    pl: "",
    de: "",
    it: "",
    fr: "",
    exact: true,
    component: Launch,
  },
  [START_PAGE]: {
    es: "/inicio",
    en: "/start",
    pl: "/początek",
    de: "/anfang",
    it: "/inizio",
    fr: "/début",
    exact: true,
    component: StartedLaunch,
  },
  [THANK_YOU_PAGE]: {
    es: "/gracias",
    en: "/thank-you",
    pl: "/o-nas",
    de: "/thank-you", // TODO: Missing translation
    it: "/thank-you", // TODO: Missing translation
    fr: "/a-propos",
    exact: true,
    component: ThankYou,
  },
};
