import React from "react"
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import HeroTitle from "utils/components/HeroTitle";
import {getCurrentInstanceId} from "services/instances";
import {HERO_IMG} from "modules/landing/prelaunch/components/PreLaunchHero/consts/index.js"
import TranslatedLink from "routes/components/TranslatedLink";
import {HOME_PAGE} from "routes/constants"

function ThankYou() {
  return (
    <HeroTitle
      title={
        <FormattedMessage
          id="ThankYouPage.title"
          description="Thank you page title"
          defaultMessage="Thanks for joining the waiting list.{br}{br}We will keep you updated!"
          values={{br: <br/>}}/>
      }
      backgroundImage={HERO_IMG[getCurrentInstanceId()]}>
      <Link 
        className="btn btn-gunmetal text-uppercase mt-4 mb-5 mt-lg-4"
        to={TranslatedLink.getLink(HOME_PAGE)}>
        <FormattedMessage
            id="ThankYouPage.goBack"
            description="Go back link text in the Thank you page"
            defaultMessage="Go back"/>
      </Link>
    </HeroTitle>
  )
}

export default ThankYou