import imgLatam from "modules/landing/launch/components/LaunchHowWorksPage/img/htw-latam.png"
import imgUsa from "modules/landing/launch/components/LaunchHowWorksPage/img/htw-usa.png"
import imgEurope from "modules/landing/launch/components/LaunchHowWorksPage/img/htw-europe.png"

export const VIDEO_URL_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: 'https://www.youtube.com/embed/moB89ULDrXU',
  [process.env.REACT_APP_MX_INSTANCE_ID]: 'https://www.youtube.com/embed/2lkl0wDNXI4',
  [process.env.REACT_APP_USA_INSTANCE_ID]: 'https://www.youtube.com/embed/LAmC4a6onDk',
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    "url": {
      "de-eu": "https://vimeo.com/624271810/ce01970ba5",
      "en-eu": "https://vimeo.com/624271810/ce01970ba5",
      "es-eu": "https://vimeo.com/624273814/f388f66018",
      "fr-eu": "https://vimeo.com/624271810/ce01970ba5",
      "it-eu": "https://vimeo.com/624271810/ce01970ba5",
      "pl-eu": "https://vimeo.com/624271810/ce01970ba5"
    }
  }
}

export const IMG_HOW_IT_WORKS_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: imgLatam,
  [process.env.REACT_APP_MX_INSTANCE_ID]: imgLatam,
  [process.env.REACT_APP_USA_INSTANCE_ID]: imgUsa,
  [process.env.REACT_APP_EU_INSTANCE_ID]: imgEurope,
}