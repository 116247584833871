import React from "react";
import {Helmet} from "react-helmet";
import {getCurrentInstanceId} from "services/instances";
import {
  ANALYTICS_IDS_BY_INSTANCE,
  CONVERSION_TRACKING_IDS_BY_INSTANCE,
  PIXEL_FACEBOOK_IDS_BY_INSTANCE
} from "components/AnalyticTagByInstance/consts"

function AnalyticTagByInstance() {
  const googleAnalyticsId = ANALYTICS_IDS_BY_INSTANCE[getCurrentInstanceId()]
  const conversionTrackingsId = CONVERSION_TRACKING_IDS_BY_INSTANCE[getCurrentInstanceId()]
  const pixelFacebookId = PIXEL_FACEBOOK_IDS_BY_INSTANCE[getCurrentInstanceId()]

  return (
    <Helmet>
      { googleAnalyticsId && 
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}/>
      }
      { googleAnalyticsId && 
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}');
          `}
        </script>
      }
      { conversionTrackingsId &&
        <script  src={`https://www.googletagmanager.com/gtag/js?id=${conversionTrackingsId}`}/>
      }
      { conversionTrackingsId &&
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${conversionTrackingsId}');
          `}
        </script>
      }
      { pixelFacebookId &&
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelFacebookId}');
            fbq('track', 'PageView');
          `}
        </script>
      }
      { pixelFacebookId && 
        <noscript>
          {`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelFacebookId}&ev=PageView&noscript=1" />
          `}
        </noscript>
      }
    </Helmet>
  )
}

export default AnalyticTagByInstance;