import {defineMessages} from "react-intl";

const validationMessages = defineMessages({
  required: {
    id: "FormMessages.required",
    description: "Required message for form validation",
    defaultMessage: "Este campo es requerido"
  },
  email: {
    id: "FormMessages.invalidEmail",
    description: "Invalid email message for form validation",
    defaultMessage: "Dirección de correo inválida"
  },
  contactAlreadyExist: {
    id: "FormMessages.contactAlreadyExist",
    description: "Invalid email message for promise validation",
    defaultMessage: "El contacto ya existe"
  },
  invalidPhoneNumber: {
    id: "FormMessages.invalidPhoneNumber",
    description: "Invalid phone number message for promise validation",
    defaultMessage: "Teléfono invalido"
  }
});

export default validationMessages;