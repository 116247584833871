import {
  INSTANCE_EUROPE,
  INSTANCE_LATAM,
  INSTANCE_MARINE,
  INSTANCE_MEXICO,
  INSTANCE_USA
} from "services/instances/constants";

/**
 * Returns the current instance.
 * @return {string}
 */
export const getCurrentInstanceId = () => {
  const {location} = window;
  const parts = location.pathname.split('/');
  const instanceCode = parts[1].split('-')[1];

  switch (instanceCode) {
    case 'la':
      return process.env.REACT_APP_LATAM_INSTANCE_ID;

    case 'mx':
      return process.env.REACT_APP_MX_INSTANCE_ID;

    case 'us':
      return process.env.REACT_APP_USA_INSTANCE_ID;

    case 'eu':
      return process.env.REACT_APP_EU_INSTANCE_ID;

    default:
      return process.env.REACT_APP_LATAM_INSTANCE_ID;
  }
};

export const isUSA = () => getCurrentInstanceId() === process.env.REACT_APP_USA_INSTANCE_ID;
export const isLATAM = () => getCurrentInstanceId() === process.env.REACT_APP_LATAM_INSTANCE_ID;
export const isMEXICO = () => getCurrentInstanceId() === process.env.REACT_APP_MX_INSTANCE_ID;
export const isEUROPE = () => getCurrentInstanceId() === process.env.REACT_APP_EU_INSTANCE_ID;

/**
 * Returns the language from the browser
 * @returns {string}
 */
const getBrowserLanguage = () => {
  return navigator.language.split(/[-_]/)[0];
};

/**
 * This method should return the instance based on the location of the user
 * This method DOESN'T RETURN THE CURRENT INSTANCE CODE.
 * TODO get the location of the user and return the right instance based on that
 * @return {string}
 */
const getInstanceCode = () => {
  return "la";
};

/**
 * Returns the current language and instance code from the current url
 * @returns {string[]}
 */
export const getCurrentLanguageAndInstanceCode = () => {
  let path = window.location.pathname.split("/");
  let lang = getBrowserLanguage(),
      instance = getInstanceCode();
  const regexp = /^([a-z]{2})-([a-z]{2})$/;
  if (path.length >= 2) {
    path.shift();  // removes the first value which is an empty string
    const firstPart = path.shift();  // gets the first part of the path, which is supposed to be "es-la", for example
    if (regexp.test(firstPart)) {
      const matches = firstPart.match(regexp);
      lang = matches[1];
      instance = matches[2];
    }
  }
  return [lang, instance];
};

/**
 * Retrieves a link to an instance
 * @param name
 * @returns {string}
 */
export const getInstanceLink = name => {
  const instances = {
    [INSTANCE_USA.toLowerCase()]: {
      lang: "en",
      code: "us",
      name: "USA"
    },
    [INSTANCE_MARINE.toLowerCase()]: {
      lang: "en",
      code: "sm",
      name: "MARINE"
    },
    [INSTANCE_EUROPE.toLowerCase()]: {
      lang: ["en", "es", "it", "fr", "de", "pl"].indexOf(navigator.language) > -1 ? navigator.language : "en",
      code: "eu",
      name: "Europe"
    },
    [INSTANCE_MEXICO.toLowerCase()]: {
      lang: "es",
      code: "mx",
      name: "Mexico"
    },
    [INSTANCE_LATAM.toLowerCase()]: {
      lang: "es",
      code: "la",
      name: "Latam"
    }
  };
  const instance = instances[name.toLowerCase()];
  return `/${instance.lang}-${instance.code}`;
};
