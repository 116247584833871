import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/SeeMoreButton.scss";
import {Link} from "react-router-dom";

const SeeMoreButton = ({href, className, children}) => {
  if (href.includes("http"))
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames({
              "see-more-button": true,
              [className]: className !== undefined
            })}>
          {children}
          <i className="sp-arrow-right"/>
        </a>
    );

  return (
      <Link
          to={href}
          className={classNames({
            "see-more-button": true,
            [className]: className !== undefined
          })}>
        {children}
        <i className="sp-arrow-right"/>
      </Link>
  )
};

// TODO eventually this component will be able to render a Link or an <a/>

SeeMoreButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default SeeMoreButton;