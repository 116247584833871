export const SPRADLING_URL = 'https://spradling.group'
export const SENDINBLUE_API_URL = 'https://api.sendinblue.com/v3/contacts'
export const PIPEDREAM_API_URL = 'https://enh83g4hymd83ze.m.pipedream.net'
export const PIPEDREAM_NOTIFY_EMAIL_URL = 'https://enr8z8fzasugecz.m.pipedream.net'
export const DUPLICATE_PARAMETER_ERROR = 'duplicate_parameter'
export const INVALID_PARAMETER_ERROR = 'invalid_parameter'

export const SENDINBLUE_REFERENCE_BY_INSTANCE_ID = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    apiKey: process.env.REACT_APP_SENDINBLUE_LATAM_API_KEY,
    listIdsPrelaunch: [108],
    listIds: [109],
    regionKey: "DEPARTAMENTO",
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    apiKey: process.env.REACT_APP_SENDINBLUE_MEXICO_API_KEY,
    listIdsPrelaunch: [29],
    listIds: [30],
    regionKey: "ESTADO",
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]: {
    apiKey: process.env.REACT_APP_SENDINBLUE_USA_API_KEY,
    listIdsPrelaunch: [69],
    listIds: [75],
    regionKey: "STATE",
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    apiKey: process.env.REACT_APP_SENDINBLUE_EUROPE_API_KEY,
    listIdsPrelaunch: {
      "de-eu": [208],
      "en-eu": [204],
      "es-eu": [201],
      "fr-eu": [206],
      "it-eu": [205],
      "pl-eu": [207]
    },
    listIds: [203],
    regionKey: "DEPARTAMENTO_ESTADO"
  }
}

export const TRACKED_ANALYTICS_URL = [
  {
    name: "LATAM",
    url: "https://spradling.group/es-la?utm_source=Web&utm_medium=Showroom&utm_campaign=Pre-lanzamiento"
  },
  {
    name: "MEXICO",
    url: "https://spradling.group/es-mx?utm_source=Web&utm_medium=Showroom&utm_campaign=Pre-lanzamiento",
  },
  {
    name: "USA",
    url: "https://spradling.group/en-us?utm_source=Web&utm_medium=Showroom&utm_campaign=Pre-launch",
  },
  {
    name: "EUROPE",
    url: "https://www.spradling.eu/?utm_source=Web&utm_medium=Showroom&utm_campaign=Pre-launch",
    urlByCountry: {
      "de-eu": "https://www.spradling.eu/de",
      "en-eu": "https://www.spradling.eu/",
      "es-eu": "https://www.spradling.eu/es",
      "fr-eu": "https://www.spradling.eu/fr",
      "it-eu": "https://www.spradling.eu/it",
      "pl-eu": "https://www.spradling.eu/pl"
    }
  }
]

export const SPRADLING_HOME_TRACKED_URL = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: TRACKED_ANALYTICS_URL[0].url,
  [process.env.REACT_APP_MX_INSTANCE_ID]: TRACKED_ANALYTICS_URL[1].url,
  [process.env.REACT_APP_USA_INSTANCE_ID]: TRACKED_ANALYTICS_URL[2].url,
  [process.env.REACT_APP_EU_INSTANCE_ID]: TRACKED_ANALYTICS_URL[3],
}
