
import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import { IntlProvider } from 'react-intl';
import esMessages from "locales/es"
import enMessages from "locales/en"
import deMessages from "locales/de"
import frMessages from "locales/fr"
import itMessages from "locales/it"
import plMessages from "locales/pl"

const messages = {
	es: esMessages,
	en: enMessages,
	de: deMessages,
	fr: frMessages,
	it: itMessages,
	pl: plMessages
}

const ConnectedIntlProvider = ({children, locale = 'es'}) => (
	/**
	 * This key value is to force react to create a new instance an re-rendering everything
	 * @see https://github.com/yahoo/react-intl/issues/243#issuecomment-166030664
	 */
	<IntlProvider
		key={locale}
		locale={locale}
		textComponent={Fragment}
		messages={messages[locale]}
	>
		{children}
	</IntlProvider>
);

ConnectedIntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
	locale: PropTypes.string.isRequired
};

export default ConnectedIntlProvider