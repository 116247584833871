import leftLatam from "modules/landing/common/ImagesRow/img/left-side-latam.png"
import rightLatam from "modules/landing/common/ImagesRow/img/right-side-latam.png"
import leftUsa from "modules/landing/common/ImagesRow/img/left-side-usa.png"
import rightUsa from "modules/landing/common/ImagesRow/img/right-side-usa.png"
import leftEurope from "modules/landing/common/ImagesRow/img/left-side-europe.png"
import rightEurope from "modules/landing/common/ImagesRow/img/right-side-europe.png"

export const ROW_IMAGES = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    left: leftLatam,
    right: rightLatam
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    left: leftLatam,
    right: rightLatam
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]: {
    left: leftUsa,
    right: rightUsa
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    left: leftEurope,
    right: rightEurope
  },
}