import React from 'react';
import RouteManager from "routes"
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "apollo/client";
import {LanguageProvider} from "context/languageContext"
import "./styles/SpradlingShowroomApp.scss"

function SpradlingShowroomApp() {
  return (
    <ApolloProvider client={apolloClient}>
      <LanguageProvider>
        <RouteManager />
      </LanguageProvider>
    </ApolloProvider>
  );
}

export default SpradlingShowroomApp;
