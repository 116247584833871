import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {getCurrentLanguageAndInstanceCode} from "services/instances";

export const RedirectToInstance = () => {
  const [countryCode, setCountryCode] = useState(null)
  const [instanceCode, setInstanceCode] = useState(null)
  let [lang] = getCurrentLanguageAndInstanceCode()

  /**
   * Select instance depending on country code
   */
  useEffect(() => {
    if (countryCode) {
      if ([
        'CO',
        'AR',
        'UY',
        'PY',
        'EC',
        'BO',
        'VE',
        'BR',
        'CL',
      ].indexOf(countryCode) > -1)
        setInstanceCode('la')
      else if (['US'].indexOf(countryCode) > -1)
        setInstanceCode('us')
      else
        setInstanceCode('us')
    }
  }, [countryCode])

  /**
   * Fetch the country based on user's IP address
   */
  useEffect(() => {
    fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_API_KEY}`)
      .then(response => response.json())
      .then(data => {
        setCountryCode(data.country_code)
      })
  }, [])

  if (!instanceCode)
    return null

  // Hard coded because all translations aren't ready as of today
  if (instanceCode === 'us')
    lang = 'en'
  else if(instanceCode === 'la')
    lang = 'es'

  return (
    <Redirect to={`${lang}-${instanceCode}`}/>
  )
};

export default RedirectToInstance;