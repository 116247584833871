// export const SHOWROOM_URL_BY_INSTANCE = {
//   [process.env.REACT_APP_LATAM_INSTANCE_ID]:
//     "https://viz360.com/content/360cgi/proquinal/hotel-latam/",
//   [process.env.REACT_APP_MX_INSTANCE_ID]:
//     "https://viz360.com/content/360cgi/spradling/hotel-latam/",
//   [process.env.REACT_APP_USA_INSTANCE_ID]:
//     "https://viz360.com/content/360cgi/spradling/hotel-usa/",
//   [process.env.REACT_APP_EU_INSTANCE_ID]: {
//     url: {
//       "de-eu": "https://viz360.com/content/360cgi/spradling/hotel-europe/",
//       "en-eu": "https://viz360.com/content/360cgi/spradling/hotel-europe/",
//       "es-eu": "https://viz360.com/content/360cgi/spradling/hotel-europa/",
//       "fr-eu": "https://viz360.com/content/360cgi/spradling/hotel-europe/",
//       "it-eu": "https://viz360.com/content/360cgi/spradling/hotel-europe/",
//       "pl-eu": "https://viz360.com/content/360cgi/spradling/hotel-europe/",
//     },
//   },
// };

const SHOWROOM_S3_URL_EUROPE_ES =
  "http://showroom-europe-en.s3-website-us-west-2.amazonaws.com/";

const SHOWROOM_S3_URL_USA =
  "http://showroom-usa.s3-website-us-west-2.amazonaws.com/";

export const SHOWROOM_URL_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: SHOWROOM_S3_URL_EUROPE_ES,
  [process.env.REACT_APP_MX_INSTANCE_ID]: SHOWROOM_S3_URL_EUROPE_ES,
  [process.env.REACT_APP_USA_INSTANCE_ID]: SHOWROOM_S3_URL_USA,
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    url: {
      "de-eu": SHOWROOM_S3_URL_EUROPE_ES,
      "en-eu": SHOWROOM_S3_URL_EUROPE_ES,
      "es-eu": SHOWROOM_S3_URL_EUROPE_ES,
      "fr-eu": SHOWROOM_S3_URL_EUROPE_ES,
      "it-eu": SHOWROOM_S3_URL_EUROPE_ES,
      "pl-eu": SHOWROOM_S3_URL_EUROPE_ES,
    },
  },
};
