import React from "react";
import {FormattedMessage} from "react-intl";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {H2_TAG} from "utils/components/TitleUnderlined/constants";
import ImagesRow from "modules/landing/common/ImagesRow"
import { isEUROPE } from "services/instances";

const LaunchOwnCombinations = () => (
  <>
    <div className="container text-center pt-3 pb-3 pt-lg-4 pb-lg-4">
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-lg-10">
          <TitleUnderlined
            hTag={H2_TAG}>
            <FormattedMessage
              id="LaunchOwnCombinationsPage.title"
              description="Title in pre-launch, stages section"
              defaultMessage="Diferentes Escenarios"/>
          </TitleUnderlined>
        </div>
      </div>
    </div>
    <ImagesRow/>
    <div className="container text-center pt-3 pb-3 pt-lg-4 pb-lg-4">
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-lg-10">
          <p className="mb-4 mb-lg-3">
            <FormattedMessage
              id={!isEUROPE() ? "LaunchOwnCombinationsPage.description" : "LaunchOwnCombinationsPage.description.eu"}
              description="Text in pre-launch, stages section"
              values={{
                br: <br/>,
                strong: chunks => <strong>{chunks}</strong>
              }}/>
          </p>
        </div>
      </div>
    </div>
  </>  
);

export default LaunchOwnCombinations;