import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {SpradlingShowroomRoutes} from "routes/constants";
import {formatRoute} from 'react-router-named-routes';
import {getCurrentLanguageAndInstanceCode} from "services/instances";

export default class TranslatedLink extends Component {

  /**
   * Returns the url path
   * @return {string}
   */
  static getLink(name, params) {
    const [lang, instanceCode] = getCurrentLanguageAndInstanceCode();
    return `/${lang}-${instanceCode}${formatRoute(SpradlingShowroomRoutes[name][lang], params)}`
  }

  render() {
    const {name, params, children, staticContext, ...rest} = this.props;
    return (
        <Link
            to={TranslatedLink.getLink(name, params)}
            {...rest}>
          {children}
        </Link>
    )
  }
}

TranslatedLink.propTypes = {
  name: PropTypes.string,
  params: PropTypes.object
};
