import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from '@apollo/client';
import {instanceQuery} from "components/Footer/query";
import {getCurrentInstanceId} from "services/instances";
import "./styles/Header.scss";

const Header = () => {
  const queryVariables = {instanceId: getCurrentInstanceId()}
  const { loading, data: instanceData } = useQuery(instanceQuery, { variables: queryVariables});

  return (
    <header className="sp_showroom-header">
      <div className="container-fluid h-100">
        <div className="row no-gutters align-items-center h-100">
          <div className="col-10 col-md-11 col-lg-11 text-center">
            <div className="header">
              {loading && <Skeleton className="header-logo" width={150} height={32}/>}
              { instanceData && 
                <img className="header-logo" src={instanceData.instance.logoSmall} alt="Spradling Group" title="Spradling Group"/>
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  )
};

export default Header;
