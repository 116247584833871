import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind";
import "./styles/SubHeroContent.scss"

export default function SubHeroContent({children, theme, className, hideShape}) {
  return (
      <div className={classNames({
        "sub-hero-content": true,
        [`sub-hero-content--${theme}`]: true,
        "sub-hero-content--hide-shape": hideShape,
        [className]: true
      })}>
        <div className="sub-hero-content__wrapper">
          {children}
        </div>
      </div>
  )
}

SubHeroContent.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf([
    "green",
    "green-dark",
    "white",
    "pink",
    "purple"
  ]).isRequired,
  className: PropTypes.string,
  hideShape: PropTypes.bool
}

SubHeroContent.defaultProps = {
  theme: "pink",
  className: "",
  hideShape: false
}