import React from "react";
import PropTypes from "prop-types";
import {ErrorMessage} from "formik";

export default function FormErrorMessage({name, color}) {
  return (
    <ErrorMessage className={`form-error-message form-error-message--${color}`} component="div" name={name}/>
  );
}

FormErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["red", "black", "white"])
};

FormErrorMessage.defaultProps = {
  color: "red"
};