import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import SubHeroContent from "utils/components/SubHeroContent";
import TitleUnderlined from "utils/components/TitleUnderlined";
import { H2_TAG } from "utils/components/TitleUnderlined/constants";
import { SCENES } from "./constants";
import "./styles/DifferentStages.scss";
import { isEUROPE } from "services/instances";

const DifferentStages = ({
  subHeroTheme = "white",
  showButton = true,
  handleClick,
}) => (
  <SubHeroContent theme={subHeroTheme} hideShape>
    <div className="container text-center pt-3 pb-3 pt-lg-4 pb-lg-4">
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-lg-10">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="DifferentStagesPage.title"
              description="Title in pre-launch, stages section"
              defaultMessage="Diferentes Escenarios"
            />
          </TitleUnderlined>
          <p className="mb-4 mb-lg-3">
            <FormattedMessage
              id={
                !isEUROPE()
                  ? "DifferentStagesPage.description"
                  : "DifferentStagesPage.description.eu"
              }
              description="Text in pre-launch, stages section"
              defaultMessage="En Spradling queremos inspirar el mundo innovando cada día en nuestros diseños y creando nuevas experiencias para cada sector. Con nuestro Nuevo Showroom Virtual interactuarás con nuestras tapicerías técnicas desde cerca y en diferentes escenarios:"
            />
          </p>
        </div>
        <div className="row justify-content-center mt-5">
          {SCENES.map(({ title, category, img }, i) => {
            const isPair = i % 2 === 0 ? true : false;
            if (isPair) {
              return (
                <div key={`key_${i}_${title}`} className="mx-3 mb-5">
                  <p className="text-size-extra-small text-size-lg-small text-uppercase my-2">
                    <FormattedMessage
                      id={`scenes.${title}`}
                      description="scene label"
                    />
                  </p>
                  <div
                    className="scene--img"
                    style={{
                      backgroundImage: `url(${img})`,
                    }}
                  />
                </div>
              );
            }
            return (
              <div key={`key_${i}_${title}`} className="mx-3">
                <div
                  className="scene--img"
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                />
                <p className="text-size-extra-small text-size-lg-small text-uppercase my-2">
                  <FormattedMessage
                    id={`scenes.${title}`}
                    description="scene label"
                  />
                </p>
              </div>
            );
          })}
        </div>
        {showButton && (
          <button
            className="btn btn-gunmetal text-uppercase mt-4 mb-5 mt-lg-4"
            type="button"
            onClick={handleClick}
          >
            <FormattedMessage
              id="DifferentStagesPage.requestAccess"
              defaultMessage="Solicitar acceso"
            />
          </button>
        )}
      </div>
    </div>
  </SubHeroContent>
);

DifferentStages.propTypes = {
  showButton: PropTypes.bool.isRequired,
  subHeroTheme: PropTypes.string,
  showMainTitle: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default DifferentStages;
