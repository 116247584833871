import gql from 'graphql-tag'

export const singleInstanceFragment = gql`
  fragment SingleInstanceFragment on InstanceNode {
    name
    code
    logoSmall
  }
`;

export const socialMediaInstanceFragment = gql`
  fragment SocialMediaInstanceFragment on InstanceNode {
    facebookUrl
    instagramUrl 
    linkedinUrl
  }
`;