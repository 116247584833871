import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from '@apollo/client';
import {FormattedMessage} from "react-intl";
import NewsletterForm from "components/Footer/components/NewsletterForm";
import SeeMoreButton from "utils/components/SeeMoreButton";
import {getCurrentInstanceId} from "services/instances";
// import {INSTANCE_LATAM, INSTANCE_MEXICO, INSTANCE_USA, INSTANCE_MARINE} from "services/instances/constants"
import {instanceQuery, socialMediaInstanceQuery} from "components/Footer/query";
import {TRACKED_ANALYTICS_URL} from "consts"
import "./styles/Footer.scss";

export default function Footer() {
  const queryVariables = {instanceId: getCurrentInstanceId()}
  const { loading: loadingInstance, data: instanceData } = useQuery(instanceQuery, { variables: queryVariables});
  const { loading: loadingSocialMedia, data: socialMediaData } = useQuery(socialMediaInstanceQuery, { variables: queryVariables});

  return (
      <footer className="spglobal-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-4">
              <div className="row">
                <div className="col-6 col-md-12">
                  <div className="footer-links footer-links--instances">
                    {/* TODO: refactor this, use this way for tranck analytics */}
                    {/* {
                      [INSTANCE_LATAM, INSTANCE_MEXICO, INSTANCE_USA].map((instance, index) =>
                        <a
                          key={index}
                          href={`${SPRADLING_URL}${getInstanceLink(instance)}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          Spradling<sup>®</sup> {instance}
                        </a>
                      )
                    } */}
                     {/* <a href="https://spradling.eu/" target="_blank" rel="noopener noreferrer">
                      Spradling<sup>®</sup> EUROPE
                    </a> */}
                    {
                      TRACKED_ANALYTICS_URL.map(({name, url}) => 
                      <a
                        key={`key_${name}`}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer">
                        Spradling<sup>®</sup> {name}
                      </a>
                      )
                    }
                  </div>
                </div>
                <div className="col-6 col-md-12">
                  {
                    getCurrentInstanceId() === process.env.REACT_APP_LATAM_INSTANCE_ID &&
                    <div className="footer-calypso">
                      <h5>
                        <FormattedMessage
                            id="Footer.calypsoTitle"
                            description="Calypso title in the footer"
                            defaultMessage="Calypso"/>
                      </h5>
                      <p>
                        <FormattedMessage
                            id="Footer.calypsoDescription"
                            description="Calypso description in the footer"
                            defaultMessage="Nuestras Tiendas en Colombia y Costa Rica"/>
                      </p>
                      <SeeMoreButton href="https://www.orgcalypso.com/">
                        <FormattedMessage
                            id="Footer.seeMore"
                            description="Ver más button label in footer"
                            defaultMessage="Ver más"/>
                      </SeeMoreButton>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <h5>
                <FormattedMessage
                    id="Footer.newsletter"
                    description="Newsletter section title"
                    defaultMessage="Newsletter"/>
              </h5>
              <NewsletterForm/>
            </div>
            <div className="col-12 col-md-4">
              <h5>
                <FormattedMessage
                    id="Footer.socialNetworks"
                    description="Redes sociales section title in footer"
                    defaultMessage="Redes sociales"/>
              </h5>
              <div className="footer-links footer-links--social">
                {loadingSocialMedia && <Skeleton count={3} />}
                {socialMediaData && socialMediaData.instance.facebookUrl && 
                  <a href={socialMediaData.instance.facebookUrl} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook fa-fw"/> Facebook
                  </a>
                }
                {socialMediaData && socialMediaData.instance.instagramUrl && 
                  <a href={socialMediaData.instance.instagramUrl} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-instagram fa-fw"/> Instagram
                  </a>
                }
                {socialMediaData && socialMediaData.instance.linkedinUrl && 
                  <a href={socialMediaData.instance.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-linkedin fa-fw"/> Linkedin
                  </a>
                }
              </div>
            </div>
          </div>
          <div className="footer-copyright text-center">
            {loadingInstance && <Skeleton width={150} height={32}/>}
            { instanceData && 
              <img src={instanceData.instance.logoSmall} alt="Spradling Group" title="Spradling Group"/>
            }
            <p className="text-uppercase mb-0">&copy;{new Date().getFullYear()} Spradling&reg; Group</p>
          </div>
        </div>
      </footer>
  )
};
