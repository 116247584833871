import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LayoutShowroom from "components/LayoutShowroom";
import TranslatedLink from "routes/components/TranslatedLink";
import { START_PAGE } from "routes/constants";

const ShowroomWelcome = ({ handleClick }) => (
  <LayoutShowroom>
    <div className="container w-lg-50 text-center">
      <div className="row justify-content-center">
        <div className="col col-md-6 col-lg-12 mx-5 mx-lg-5 align-self-center">
          <h1 className="hero-title-heading">
            <FormattedMessage
              id="Home.heroTitle"
              defaultMessage="Bienvenido al showroom virtual de Spradling"
            />
          </h1>
          <Link
            className="btn btn-outline-light mt-5"
            to={TranslatedLink.getLink(START_PAGE)}
          >
            <FormattedMessage id="Home.start" defaultMessage="Iniciar" />
          </Link>
        </div>
      </div>
    </div>
  </LayoutShowroom>
);

ShowroomWelcome.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default ShowroomWelcome;
