import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/HeroTitle.scss";

export default function HeroTitle({title, image, backgroundImage, align, children}) {
  return (
      <div
          className="hero-title"
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}>
        <div className={classNames({
          "hero-title__content": true,
          [`hero-title__content--align-${align}`]: true,
        })}>
          <div className="container">
            {image && image}
            <h1 className="hero-title-heading">
              {title}
            </h1>
            {children && children}
          </div>
        </div>
      </div>
  )
}

HeroTitle.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  image: PropTypes.node,
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.element,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};