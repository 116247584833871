import React from "react";
import PropTypes from 'prop-types';
import {Route, Switch} from "react-router-dom";
import {SpradlingShowroomRoutes} from "routes/constants";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";
import {useLanguage} from 'context/languageContext'

const RenderRoutes = ({match}) => {
  const {state: {lang}} = useLanguage()

  return (
    <ConnectedIntlProvider locale={lang}>
      <>
        <Switch>
          {
            Object.keys(SpradlingShowroomRoutes).map((key, index) => {
                const route = SpradlingShowroomRoutes[key];
                return (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={`${match.path}${route[match.params.language]}`}
                    component={route.component}/>
                )
              }
            )
          }
        </Switch>
      </>
    </ConnectedIntlProvider>
  )
}

RenderRoutes.propTypes = {
  match: PropTypes.object,
};

export default RenderRoutes;