import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import RenderRoutes from "routes/components/RenderRoutes/index.js"
import RedirectToInstance from "app/components/RedirectToInstance";
import NoMatch from "app/components/NoMatch"

function RouteManager() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={RedirectToInstance}/>
        <Route path="/:language([a-z]{2})-:instance([a-z]{2})" component={RenderRoutes}/>
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  )
}

export default RouteManager;
