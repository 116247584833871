import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import classNames from "classnames/bind";
import "./styles/NewsletterForm.scss";
import {useMutation} from "@apollo/client";
import subscribeToNewsletterMutation from "components/Footer/components/NewsletterForm/mutation";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {getCurrentInstanceId} from "services/instances";
import validationMessages from "utils/forms/messages";
import FormErrorMessage from "utils/components/FormErrorMessage"

export default function NewsletterForm() {
  const intl = useIntl();
  const [subscribed, setSubscribed] = useState(false);
  const [subscribe] = useMutation(subscribeToNewsletterMutation, {
    onCompleted: ({subscribeToNewsletter: {subscribed}}) => {
      if (subscribed)
        setSubscribed(true);
    }
  });

  function handleSubmit(values, {setSubmitting, resetForm}) {
    const {email} = values;
    const instanceId = getCurrentInstanceId();
    const promise = subscribe({
      variables: {
        input: {
          email,
          instanceId
        }
      }
    });
    promise.then(data => {
      setSubmitting(false);
      resetForm();
    });
    promise.catch(() => {
      setSubmitting(false);
    });
  }

  const initialValues = {
    email: ""
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required(intl.formatMessage(validationMessages.required))
        .email(intl.formatMessage(validationMessages.email)),
  });

  if (subscribed)
    return (
        <p className="white">
          <FormattedMessage
              id="NewsletterForm.thankYou"
              description="Thank you message in NewsletterForm component"
              defaultMessage="¡Tu suscripción fue exitosa!"/>
        </p>
    );

  return (
      <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}>
        {
          ({errors, isSubmitting}) => (
              <Form
                  className="form newsletter-form">
                <div className="form-group">
                  <label htmlFor="email" className="sr-only">
                    <FormattedMessage
                        id="NewsletterForm.email"
                        description="Correo electrónico label for newsletter form email field"
                        defaultMessage="Correo electrónico"/>
                  </label>
                  <FormattedMessage
                      id="NewsletterForm.placeholder"
                      description="Tu correo placeholder for newsletter form email field"
                      defaultMessage="Tu correo">
                    {
                      placeholder =>
                          <Field
                              type="email"
                              name="email"
                              id="email"
                              className={classNames({
                                "form-control": true,
                                "is-invalid": !_.isUndefined(errors.email)
                              })}
                              placeholder={placeholder}
                              disabled={isSubmitting}/>
                    }
                  </FormattedMessage>
                  <div className="invalid-feedback">
                    <FormErrorMessage name="email"/>
                  </div>
                </div>
                <button
                    type="submit"
                    className="btn btn-dark"
                    disabled={isSubmitting}>
                  {
                    isSubmitting
                        ?
                        <FormattedMessage
                            id="NewsletterForm.submitting"
                            description="Submitting label for newsletter form submit button"
                            defaultMessage="Enviando..."/>
                        :
                        <FormattedMessage
                            id="NewsletterForm.submit"
                            description="Submit label for newsletter form submit button"
                            defaultMessage="Suscribirme"/>
                  }
                </button>
              </Form>
          )
        }
      </Formik>
  )
}
