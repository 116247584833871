import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import DifferentStages from "modules/landing/common/DifferentStages";
import AboutUs from "modules/landing/common/AboutUs";
import {
  getCurrentInstanceId,
  getCurrentLanguageAndInstanceCode,
  isEUROPE,
} from "services/instances";
import { SHOWROOM_URL_BY_INSTANCE } from "modules/showroom/consts";
import Video from "modules/landing/launch/components/LaunchVideo";
import HowWorks from "modules/landing/launch/components/LaunchHowWorksPage";
import OwnCombinations from "modules/landing/launch/components/LaunchOwnCombinationsPage";
import Layout from "components/Layout";
import AnalyticTagByInstance from "components/AnalyticTagByInstance";
import "modules/landing/launch/styles/launch.scss";

const StartedLaunch = ({ history }) => {
  const showroomIframe = `
    <iframe 
      class="showroom"
      src=${
        !isEUROPE()
          ? SHOWROOM_URL_BY_INSTANCE[getCurrentInstanceId()]
          : getUrlForEurope()
      } 
      title="showroom"/>
  `;
  const showroom = DOMPurify.sanitize(showroomIframe, {
    ALLOWED_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "scrolling"],
  });

  // WARNING: used for make redirect to en-us
  useEffect(() => {
    if (isEUROPE()) {
      const [lang] = getCurrentLanguageAndInstanceCode();
      const langsForRedirect = ["de", "fr", "it", "pl"];
      if (langsForRedirect.includes(lang)) {
        history.replace("/en-eu");
        history.go(0); // WWARNING: force to reload
      }
    }
  }, [history]);

  function getUrlForEurope() {
    const [lang, instance] = getCurrentLanguageAndInstanceCode();
    const langInstance = `${lang}-${instance}`;
    const europe = SHOWROOM_URL_BY_INSTANCE[getCurrentInstanceId()];
    const url = europe.url[langInstance];

    return url;
  }

  return (
    <Layout isRenderHeader={false}>
      <AnalyticTagByInstance />
      <div dangerouslySetInnerHTML={{ __html: showroom }} />
      <Video />
      <DifferentStages showButton={false} />
      <HowWorks />
      <OwnCombinations />
      <AboutUs />
    </Layout>
  );
};

StartedLaunch.propTypes = {
  history: PropTypes.object.isRequired,
};

export default StartedLaunch;
