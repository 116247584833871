import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Showroom from "modules/showroom/ShowroomWelcome";
import DifferentStages from "modules/landing/common/DifferentStages";
import AboutUs from "modules/landing/common/AboutUs";
import {
  getCurrentLanguageAndInstanceCode,
  isEUROPE,
} from "services/instances";
import Video from "modules/landing/launch/components/LaunchVideo";
import HowWorks from "./components/LaunchHowWorksPage";
import OwnCombinations from "./components/LaunchOwnCombinationsPage";
import Layout from "components/Layout";
import AnalyticTagByInstance from "components/AnalyticTagByInstance";
import "modules/landing/launch/styles/launch.scss";

const Launch = ({ history }) => {
  // WARNING: used for make redirect to en-us
  useEffect(() => {
    if (isEUROPE()) {
      const [lang] = getCurrentLanguageAndInstanceCode();
      const langsForRedirect = ["de", "fr", "it", "pl"];
      if (langsForRedirect.includes(lang)) {
        history.replace("/en-eu");
        history.go(0); // WWARNING: force to reload
      }
    }
  }, [history]);

  return (
    <Layout>
      <AnalyticTagByInstance />
      <Showroom />
      <Video />
      <DifferentStages showButton={false} />
      <HowWorks />
      <OwnCombinations />
      <AboutUs />
    </Layout>
  );
};

Launch.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Launch;
