export const ANALYTICS_IDS_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "G-WXNQ5KXLQ4",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "G-FKEN3N8P9E",
  [process.env.REACT_APP_USA_INSTANCE_ID]: "G-5GPDS7TEPP",
  [process.env.REACT_APP_EU_INSTANCE_ID]: "G-NG6TSLSESW",
}

export const CONVERSION_TRACKING_IDS_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "AW-952987463",
  [process.env.REACT_APP_USA_INSTANCE_ID]: "",
  [process.env.REACT_APP_EU_INSTANCE_ID]: "AW-757689709",
}

export const PIXEL_FACEBOOK_IDS_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "",
  [process.env.REACT_APP_USA_INSTANCE_ID]: "",
  [process.env.REACT_APP_EU_INSTANCE_ID]: "2502003796494300",
}