import lobbyLatam from "../img/1-lobby-latam.png"
import restaurantLatam from "../img/2-restaurante-latam.png"
import roomLatam from "../img/3-room-latam.png"
import exteriorLatam from "../img/4-exterior-latam.png"
import lobbyUsa from "../img/1-lobby-usa.png"
import restaurantUsa from "../img/2-restaurante-usa.png"
import roomUsa from "../img/3-room-usa.png"
import exteriorUsa from "../img/4-exterior-usa.png"
import lobbyEurope from "../img/1-lobby-europe.png"
import restaurantEurope from "../img/2-restaurante-europe.png"
import roomEurope from "../img/3-room-europe.png"
import exteriorEurope from "../img/4-exterior-europe.png"
// import receptionImg from "../img/5-reception.png"
// import pediatryImg from "../img/6-pediatry.png"
// import room2Img from "../img/7-room2.png"
// import emergencyRoomImg from "../img/8-emergency_room.png"
import {getCurrentInstanceId} from "services/instances";

const instanceId = getCurrentInstanceId()

const SCENE_BY_INSTANCE = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: {
    lobby: lobbyLatam,
    restaurant: restaurantLatam,
    room: roomLatam,
    exterior: exteriorLatam,
  },
  [process.env.REACT_APP_MX_INSTANCE_ID]: {
    lobby: lobbyLatam,
    restaurant: restaurantLatam,
    room: roomLatam,
    exterior: exteriorLatam,
  },
  [process.env.REACT_APP_USA_INSTANCE_ID]: {
    lobby: lobbyUsa,
    restaurant: restaurantUsa,
    room: roomUsa,
    exterior: exteriorUsa,
  },
  [process.env.REACT_APP_EU_INSTANCE_ID]: {
    lobby: lobbyEurope,
    restaurant: restaurantEurope,
    room: roomEurope,
    exterior: exteriorEurope,
  },
}

export const SCENES = [
  {
    title: "lobby",
    category: "hotel",
    img: SCENE_BY_INSTANCE[instanceId].lobby
  },
  {
    title: "restaurant",
    category: "hotel",
    img: SCENE_BY_INSTANCE[instanceId].restaurant
  },
  {
    title: "room",
    category: "hotel",
    img: SCENE_BY_INSTANCE[instanceId].room
  },
  {
    title: "outdoor_area",
    category: "hotel",
    img: SCENE_BY_INSTANCE[instanceId].exterior
  },
  // {
  //   title: "reception",
  //   category: "hospital",
  //   img: receptionImg
  // },
  // {
  //   title: "padiatry",
  //   category: "hospital",
  //   img: pediatryImg
  // },
  // {
  //   title: "room",
  //   category: "hospital",
  //   img: room2Img
  // },
  // {
  //   title: "emergency_room",
  //   category: "hospital",
  //   img: emergencyRoomImg
  // },
]