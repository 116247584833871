import React from "react";
import {FormattedMessage} from "react-intl";
import { isEUROPE, getCurrentInstanceId } from "services/instances";
import {IMG_HOW_IT_WORKS_BY_INSTANCE} from "modules/landing/launch/consts"
import "./styles/LaunchHowWorks.scss"

const LaunchHowWorksPage = () => (
  <div className="how-works-container pt-4 pb-4 pt-lg-5 mb-lg-5">
    <img className="preview-virtual-rom--img mb-0 mb-lg-5" src={IMG_HOW_IT_WORKS_BY_INSTANCE[getCurrentInstanceId()]} alt="preview of virtual room"/>          
    <div className="blue-box p-5">
      <h2 className="mb-4">
        <FormattedMessage
          id="LaunchHowWorksPage.title"
          description="Launch How does it work page title"
          />
      </h2>
      <p className="paragraph">
        <FormattedMessage
          id={!isEUROPE() ? "LaunchHowWorksPage.description" : "LaunchHowWorksPage.description.eu"} 
          description="Launch How does it work page description"
          values={{br: <br/>}}/>
      </p>
    </div>
  </div>
);

export default LaunchHowWorksPage;