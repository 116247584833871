import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";

const Layout = ({ isRenderHeader = true, children }) => (
  <>
    {isRenderHeader && <Header />}
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  isRenderHeader: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
