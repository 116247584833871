import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import {getCurrentInstanceId, getCurrentLanguageAndInstanceCode, isEUROPE} from "services/instances";
import {VIDEO_URL_BY_INSTANCE} from "modules/landing/launch/consts"

const LaunchVideo = () => {
  function getVideoUrlForEurope() {
    const [lang, instance] = getCurrentLanguageAndInstanceCode()
    const langInstance = `${lang}-${instance}`
    const url = VIDEO_URL_BY_INSTANCE[getCurrentInstanceId()].url[langInstance]

    return url
  }

  return  (
    <div className="container mt-3 pt-3 pb-3 mt-lg-4 pt-lg-4 pb-lg-4">
      <div className="row">
        <div className="col">
          <div name="video_box">
            { !isEUROPE() ?
                <iframe 
                  title="video"
                  width="100%"
                  height="500"
                  src={VIDEO_URL_BY_INSTANCE[getCurrentInstanceId()]}
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
              : <Vimeo video={getVideoUrlForEurope()} responsive={true} />         
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchVideo;