import React from "react";
import {FormattedMessage} from "react-intl";
import HeroTitle from "utils/components/HeroTitle";
import TitleUnderlined from "utils/components/TitleUnderlined";
import {H2_TAG} from "utils/components/TitleUnderlined/constants";
import {getCurrentInstanceId, getCurrentLanguageAndInstanceCode, isEUROPE} from "services/instances";
import {ABOUT_US_IMG} from "modules/landing/common/AboutUs/consts"
import {SPRADLING_HOME_TRACKED_URL} from "consts"

const AboutUs = () => {
  function getUrl(){
    let url = SPRADLING_HOME_TRACKED_URL[getCurrentInstanceId()] 
    if (isEUROPE()) {
      url = getUrlForEurope()
    }

    return url
  }

  function getUrlForEurope() {
    const [lang, instance] = getCurrentLanguageAndInstanceCode()
    const langInstance = `${lang}-${instance}`
    const europe = SPRADLING_HOME_TRACKED_URL[getCurrentInstanceId()]
    const url = europe.urlByCountry[langInstance]
    
    return url
  }

  return (
    <HeroTitle
      backgroundImage={ABOUT_US_IMG[getCurrentInstanceId()]}
      align="center">
      <div className="text-center">
        <TitleUnderlined
          color="white"
          hTag={H2_TAG}>
          <FormattedMessage
            id="AboutUsPage.title"
            description="Title in pre-launch, about us section"
            defaultMessage="Diferentes Escenarios"/>
        </TitleUnderlined>
        <p>
          <FormattedMessage
            id="AboutUsPage.description"
            description="Pre-launch about us page description"
            values={{br: <br/>}}
            />
        </p>
        <a 
          className="btn btn-gunmetal text-uppercase mt-4 mt-lg-4"
          href={getUrl()}
          target="_blank" 
          rel="noopener noreferrer">
            <FormattedMessage
              id="AboutUsPage.moreInfo"
              defaultMessage="Solicitar acceso"/>
        </a>
      </div>
    </HeroTitle>
  )
}

export default AboutUs;