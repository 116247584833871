import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink, from} from "apollo-link";
import {createUploadLink} from 'apollo-upload-client';
import {getCurrentLanguageAndInstanceCode} from "services/instances";

const httpLink = createUploadLink({
  uri: new URL('graphql/', process.env.REACT_APP_SERVER_URL).href
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const [lang] = getCurrentLanguageAndInstanceCode();
  operation.setContext({
    headers: {
      'Accept-Language': lang
    }
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: from([middlewareLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id
  })
});
