import React from "react";
import PropTypes from 'prop-types';
import heroBg from "./img/hero-bg.png"
import "./styles/LayoutShowroom.scss"

const LayoutShowroom = ({children}) => (
  <div
    className="hero-title-showroom"
    style={{
      backgroundImage: `url(${heroBg})`
    }}>
    <div className="hero-title-showroom__content">
      {children}
    </div>
  </div>
);

LayoutShowroom.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutShowroom;