
import React from "react";
import {getCurrentInstanceId} from "services/instances";
import { ROW_IMAGES } from "modules/landing/common/ImagesRow/consts";
import "./styles/ImagesRow.scss"

const ImagesRow = () => (
  <div className="row no-gutters pb-3 pb-lg-4">
    <div className="col-12 col-lg-6">
      <div 
        className="divisor--img"
        style={{
          backgroundImage: `url(${ROW_IMAGES[getCurrentInstanceId()].left})`
        }}/>
    </div>
    <div className="col-12 col-lg-6">
      <div 
        className="divisor--img"
        style={{
          backgroundImage: `url(${ROW_IMAGES[getCurrentInstanceId()].right})`
        }}/>
    </div>
  </div>
);

export default ImagesRow;