import {createContext, useReducer, useContext} from "react"
import PropTypes from "prop-types";
import {getCurrentLanguageAndInstanceCode} from "services/instances"
import {LANGUAGE_CHANGE} from "./types"

const [lang] = getCurrentLanguageAndInstanceCode();
export const initialState = {lang};

const LanguageContext = createContext(initialState);
const { Provider } = LanguageContext;

function languageReducer(state, action) {
  switch (action.type) {
    case LANGUAGE_CHANGE: {
      return {lang: action.lang}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const LanguageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(languageReducer, initialState)
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <Provider value={value}>{children}</Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};


function useLanguage() {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

export {LanguageProvider, useLanguage}